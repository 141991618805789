@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/Inter/Inter-Regular.woff2") format("woff2"),
    url("../fonts/Inter/Inter-Regular.woff") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/Inter/Inter-Italic.woff2") format("woff2"),
    url("../fonts/Inter/Inter-Italic.woff") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../fonts/Inter/Inter-Medium.woff2") format("woff2"),
    url("../fonts/Inter/Inter-Medium.woff") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("../fonts/Inter/Inter-MediumItalic.woff2") format("woff2"),
    url("../fonts/Inter/Inter-MediumItalic.woff") format("woff");
}
